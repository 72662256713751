.header{
    background: linear-gradient(180deg, rgba(52,86,255,1) 0%, rgba(23, 23, 23, 0) 100%);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 15vw;
    font-family: Unbounded-Bold, serif;
    top: 0;
    position: fixed;
    z-index: 10;
    backdrop-filter: blur(5px);
    color: white;
}

.header-img{
    margin: 0 0 0 1vw;
    width: 20%;
}

.header-img img{
    height: auto;
    width: 20vw;
}

.header-title{
    margin: auto;
    font-size: 5vw;
    text-align: center;
}

.header-developers{
    margin: 0 1vw 0 0;
    width: 20%;
}

.header-developers p{
    margin: 0;
    width: auto;
    font-size: 1vw;
}
/*.header div{*/
/*    width: auto;*/
/*}*/

.header-buffer{
    height: 15vw;
}

/*@media (max-width: 768px) {*/
/*    .header{*/
/*        height: 80px;*/
/*    }*/

/*    .header-img{*/
/*        margin: 0 0 0 1vw;*/
/*        height: 50px;*/
/*        width: 30%;*/
/*    }*/

/*    .header-title{*/
/*        margin: auto;*/
/*        font-size: 16px;*/
/*    }*/

/*    .header-developers{*/
/*        margin: 0 1vw 0 0;*/
/*        font-size: 8px;*/
/*        width: 25%;*/
/*    }*/

/*    .header-developers p{*/
/*        margin: 0;*/
/*    }*/

/*    .header-buffer{*/
/*        height: 80px;*/
/*    }*/
/*}*/