html {
    width: 100vw;
    min-height: 100vh;
    scrollbar-width: none;
}
body {
    margin: 0;
    font-family: Montserrat-Regular, sans-serif;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(255, 255, 255, 0.1);
    width: fit-content;
}
body:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    backdrop-filter: blur(50px);
    z-index: -1;
}
/*div {*/
/*    width: 100%;*/
/*    max-width: 100%;*/
/*    overflow: hidden;*/
/*}*/
.buf {
    min-height: 12vw;
}
.fill {
    /*background-color: rgba(0, 0, 0, 0.2);*/
    min-height: 100%;
    width: 100%;
}
table {
    padding: 1vw;
    min-width: 100vw;
    width: 100%;
}
td {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    height: 3.5vw;
}
.fiveHeight{
    height: 5vw;
}
b {
    font-size: 2vw;
    margin: 0.5vw;
}
.line {
    background-color: rgba(0, 0, 0, 0);
    height: 1vw;
}
.title {
    font-size: 4.5vw;
}
.title b {
    font-size: 4.5vw;
}
.profileB {
    font-size: 3vw;
}
button {
    border: none;
    background: none;
    padding: 0;
}
.arrow img {
    width: 5vw;
    height: auto;
    margin: 0 2rem;
}
select {
    all: unset;
    scrollbar-width: none;
    font-size: 4.5vw;
}
form {
    display: inline;
}
.mobile-nav {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    position: fixed;
    bottom: 0;
    height: 12vw;
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.bloc-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
.bloc-icon img {
    width: 8vw;
    height: auto;
}
.bloc-icon .widthTen{
    width: 10vw
}
.inputP, .selectP {
    all: unset;
    vertical-align: middle;
    scrollbar-width: none;
    font-size: 3vw;
}
.form{
    overflow: hidden;
    vertical-align: middle;
}
.pen{
    width: 5vw;
    height: auto;
    padding-right: 1vw;
    float: right;
}
.form span select{
    width: 100%;
    display: list-item;
    font-size: 3vw;
    margin: 1vw
}
.form span .inputP{
    width: 100%;
    height: 100%
}
.form span input{
    width: 100%;
    all: unset;
    vertical-align: middle;
    scrollbar-width: none;
}

.form span{
    overflow: hidden;
    display: block;
    vertical-align: middle;
}

.fiveWidth{
    width: 5vw;
}
.minWidthTen{
    min-width: 10vw
}
.widthHundred{
    width: 100%;
}
.dutyButton{
    bottom: 12vw;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
}

.loading-screen {
    display: flex;
    position: fixed;
    top:0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    min-width: 100vw;
    width: 100%;
}

.spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(196, 196, 196, 0.1);
    border-top: 4px solid #ffffff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.hidden {
    display: none;
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
    position: absolute;
}

td input{
    all: unset;
    vertical-align: middle;
    scrollbar-width: none;
}

.calcInput{
    height: 100%;
    width: 100%;
    font-size: 5vw;
}
.calcResult{
    font-size: 5vw;
    min-width: 10vw;
}
.calcStep{
    font-size: 3vw;
    height: 3.5vw;
}
.calcHeight{
    height: 10vw;
}
.fontFive{
    width: 10vw;
    font-size: 5vw;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: #676767;
    padding: 2vw;
    border-radius: 10px;
    width: 80%;
    max-width: 100vw;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
}

.modal-body {
    max-height: 70vh;
    overflow-y: auto;
}

.close-button {
    position: absolute;
    height: 5vw;
    width: 5vw;
    top: -0.5vw;
    right: -0.5vw;
    background: transparent;
    border: none;
    font-size: 5vw;
    cursor: pointer;
}